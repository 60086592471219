require('./src/assets/scss/main.scss');
require('prismjs/themes/prism-solarizedlight.css');

// Called when prefetching for a pathname is triggered.
// We use this API hook to make sure our links exist before using them
// eslint-disable-next-line import/prefer-default-export
export const onPostPrefetchPathname = () => {
  // We get the reference to the favicon links we need to update
  const favicon32 = document.getElementById('favicon32');
  const favicon192 = document.getElementById('favicon192');
  const favicon180 = document.querySelector('link[rel=apple-touch-icon]');

  // Switch favicon if dark/light theme changes on the user's device
  function switchIcon(usesDarkMode) {
    if (usesDarkMode) {
      favicon32.href = '/favicon-dark-32.png';
      favicon180.href = '/favicon-dark-180.png';
      favicon192.href = '/favicon-dark-192.png';
    } else {
      favicon32.href = '/favicon-light-32.png';
      favicon180.href = '/favicon-light-180.png';
      favicon192.href = '/favicon-light-192.png';
    }
  }

  // Check to see if the user has dark mode enabled
  const usesDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches || false;

  // Run the switchIcon function if the user's color scheme changes
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (e) => switchIcon(e.matches));

  // We always start with light-themed favicons, so we need to update
  //  if the user has dark mode enabled
  switchIcon(usesDarkMode);
};
